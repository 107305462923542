import { isBrowser, checkUtmSourceStartsWith } from "../../utils";

export function slots() {
  // Need for build version as window undefined
  if (!isBrowser()) {
    return [];
  }

  // Define Ad unit names
  let RightColumnUnitName = "NEXT_RightColumnHalfPage1";
  let MPUTopRightUnitName = "NEXT_MPUTopRight";
  let LeaderBelowTitleUnitName = "NEXT_Leader_BellowTitle";

  // Define Ad Unit Pathways
  let RightColumnPathway = "NEXT_RightColumnHalfPage1";
  let MPUTopRightPathway = "NEXT_MPUTopRight";
  let LeaderBelowTitlePathway = "NEXT_Leader_BellowTitle";

  // prettier-ignore
  return [
    {
      "condition": !checkUtmSourceStartsWith('twtr_') && !checkUtmSourceStartsWith('yahoo_'),
      "id": "LeaderBelowTitle",
      "name": LeaderBelowTitleUnitName,
      "path": `/76730613/${LeaderBelowTitlePathway}`,
      "sizes": window.screen.width < 812 ? [[300, 250],[336,280],[320,100]] : [[728, 90]],
      "bidders": [
        { bidder: 'adagio', params: { organizationId: '1067', site: 'sportaroo-com', adUnitElementId: 'LeaderBelowTitle', environment: 'desktop', placement: `${LeaderBelowTitlePathway}` } },
        { bidder: 'adform', params: {mid: 1698814}},
        // //{ bidder: 'appnexus', params: {placementId: '20942283'}},
        { bidder: 'criteo', params: {networkId: 8147}},
        // { bidder: 'ix', params: {siteId: 623506, size: [728, 90]}},
        // //{ bidder: 'justpremium', params: {zone: 115712, allow: ["is"]}},
        // //{ bidder: 'districtm',  params: {  placementId: 16005275 } },
        // //{ bidder: 'districtmDMX',  params: { dmxid: 367604, memberid : 101868 } },
        { bidder: 'medianet', params: {cid: '8CUJF5HOT', crid: '273381276'}},
        { bidder: 'openx', params: {unit: '559450479', delDomain: 'lqr-d.openx.net'}},
        { bidder: 'onetag', params: {pubId: '654b33df39ee492'}},
        { bidder: 'pubmatic', params: {publisherId: '159940', adSlot: '5172555'}},
        // //{ bidder: 'richaudience', params: { pid: 'QReieyrtGC', supplyType: 'site' }},
        { bidder: 'sovrn', params: {tagid: 1141120}},
        // { bidder: 'teads', params: { pageId: '113724', placementId: '135706'} },
        // { bidder: 'triplelift', params: {inventoryCode: "TrendscatchersCOM_NEXT_Leader_bellowTitle_Prebid"}},
        { bidder: 'smartadserver', params: { siteId: 596129, pageId: 1823641, formatId: 115311}},
        // { bidder: 'taboola', params: { tagId: 'LeaderBelowTitle', publisherId: '1490051' }},
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' }},
        { bidder: 'sharethrough', params: {pkey: 'TsmLETM8253FHmHgQMUPQjth'} },
        { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
      ],
      "mobile_bidders": [
        { bidder: 'adagio', params: { organizationId: '1067', site: 'sportaroo-com', adUnitElementId: 'LeaderBelowTitle', environment: 'mobile', placement: `${LeaderBelowTitlePathway}` } },
        { bidder: 'adform', params: {mid: 1698814}},
        // //{ bidder: 'appnexus', params: {placementId: '20942283'}},
        { bidder: 'criteo', params: {networkId: 8147}},
        // //{ bidder: 'districtm',  params: { placementId: 16005275 } },
        // //{ bidder: 'districtmDMX',  params: { dmxid: 367604, memberid : 101868 } },
        // { bidder: 'ix', params: {siteId: 623528, size: [300, 250]}},
        // { bidder: 'ix', params: {siteId: 623528, size: [336, 280]}},
        // { bidder: 'ix', params: {siteId: 623528, size: [320, 100]}},
        // //{ bidder: 'justpremium', params: {zone: 115712, allow: ["is"]}},
        { bidder: 'medianet', params: {cid: '8CUJF5HOT', crid: '273381276'}},
        { bidder: 'onetag', params: {pubId: '654b33df39ee492'}},
        { bidder: 'openx', params: {unit: '559450479', delDomain: 'lqr-d.openx.net'}},
        { bidder: 'pubmatic', params: {publisherId: '159940', adSlot: '5172555'}},
        // //{ bidder: 'richaudience', params: { pid: '6yQQzMZSfj', supplyType: 'site' }},
        { bidder: 'sovrn', params: {tagid: 1141156}},
        // //{ bidder: 'sublime', params:{ zoneId: 33294 , notifyId: (function b(a){return a?(a^Math.random()*16>>a/4).toString(16):([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g,b)})(); return window.sublime.notifyId;})() } },
        // { bidder: 'teads', params: {pageId: '121182', placementId: '135710'}},
        // { bidder: 'triplelift', params: {inventoryCode: "TrendscatchersCOM_NEXT_Leader_bellowTitle_Prebid"}},
        { bidder: 'smartadserver', params: { siteId: 596129, pageId: 1823641, formatId: 115311}},
        // { bidder: 'taboola', params: { tagId: 'LeaderBelowTitle', publisherId: '1490051' }},
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' }},
        { bidder: 'sharethrough', params: {pkey: 'is2AFla8jFP8tl4utqaP1Mu3'} },
        { bidder: 'sharethrough', params: {pkey: 'nd6k3hbuwgTBiJnzh1Ho8j9u'} },
        { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
      ]
    },
    {
      "condition": window.screen.width < 812,
      "id": "MPUTopRight",
      "name": MPUTopRightUnitName,
      "path": `/76730613/${MPUTopRightPathway}`,
      "sizes": [[300, 250],[336,280],[320,100]],
      "mobile_bidders": [
        { bidder: 'adagio', params: { organizationId: '1067', site: 'sportaroo-com', adUnitElementId: 'MPUTopRight', environment: 'mobile', placement: `${MPUTopRightPathway}` } },
        { bidder: 'adform', params: {mid: 1698817}},
        // //{ bidder: 'appnexus', params: {placementId: '20942284'}},
        { bidder: 'criteo', params: {networkId: 8147}},
        // //{ bidder: 'districtm', params: { placementId: 16005279 } },
        // //{ bidder: 'districtmDMX', params: { dmxid: 367605, memberid : 101868 } },
        // { bidder: 'ix', params: {siteId: 623512, size: [300, 250]}},
        // { bidder: 'ix', params: {siteId: 623512, size: [336, 280]}},
        // { bidder: 'ix', params: {siteId: 623512, size: [320, 100]}},
        // //{ bidder: 'justpremium', params: {zone: 115712, allow: ["is"]}},
        { bidder: 'medianet', params: {cid: '8CUJF5HOT', crid: '339895831'}},
        { bidder: 'onetag', params: {pubId: '654b33df39ee492'}},
        { bidder: 'openx', params: {unit: '559450480', delDomain: 'lqr-d.openx.net'}},
        { bidder: 'pubmatic', params: {publisherId: '159940', adSlot: '5172555'}},
        // //{ bidder: 'richaudience', params: { pid: 'hoS2bUVNvb', supplyType: 'site' }},
        { bidder: 'sovrn', params: {tagid: 1141159}},
        // //{ bidder: 'sublime', params:{ zoneId: 33294 , notifyId: (function b(a){return a?(a^Math.random()*16>>a/4).toString(16):([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g,b)})(); return window.sublime.notifyId;})() } },
        // { bidder: 'teads', params: {pageId: '121182', placementId: '135711'}},
        // { bidder: 'triplelift', params: {inventoryCode: "TrendscatchersCOM_NEXT_MPUTopRight_Prebid"}},
        { bidder: 'smartadserver', params: { siteId: 596129, pageId: 1823641, formatId: 115311}},
        // { bidder: 'taboola', params: { tagId: 'MPUTopRight', publisherId: '1490051' }},
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' }},
        { bidder: 'sharethrough', params: {pkey: 'W33OPwhqJzU715k6MqrjhusJ'} },
        { bidder: 'sharethrough', params: {pkey: 'S9G7SluVDCwKOYqsLu2Q46c0'} },
        { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
      ]
    },
    {
      "condition": window.screen.width > 812,
      "id": "RightColumn",
      "name": RightColumnUnitName,
      "path": `/76730613/${RightColumnPathway}`,
      "sizes": window.screen.width > 1440 ? [[300, 600],[160, 600]] : [[160, 600]],
      "bidders": [
        { bidder: 'adagio', params: { organizationId: '1067', site: 'sportaroo-com', adUnitElementId: 'RightColumn', environment: 'desktop', placement: `${RightColumnPathway}` } },
        { bidder: 'adform', params: {mid: 1698845}},
        { bidder: 'sovrn', params: {tagid: 1141137}},
        { bidder: 'sovrn', params: {tagid: 1141138}},
        { bidder: 'sovrn', params: {tagid: 1141139}},
        //  // { bidder: 'appnexus', params: {placementId: '20942290'}},
        { bidder: 'criteo', params: {networkId: 8147}},
        //   //{ bidder: 'districtm', params: { placementId: 16005296 } },
        //   //{ bidder: 'districtmDMX', params: { dmxid: 367608, memberid : 101868 } },
        //   { bidder: 'ix', params: {siteId: 623502, size: [160, 600]}},
        //   //{ bidder: 'justpremium', params: {zone: 115712, allow: ["is"]}},
        { bidder: 'medianet', params: {cid: '8CUJF5HOT', crid: '548276061'}},
        { bidder: 'onetag', params: {pubId: '654b33df39ee492'}},
        { bidder: 'openx', params: {unit: '559450484', delDomain: 'lqr-d.openx.net'}},
        { bidder: 'pubmatic', params: {publisherId: '159940', adSlot: '5172555'}},
        //   //{ bidder: 'richaudience', params: { pid: 'HOpAKTXHmc', supplyType: 'site' }},
        //   { bidder: 'teads', params: {pageId: '121182', placementId: '131481'}},

        //   { bidder: 'triplelift', params: { inventoryCode: "TrendscatchersCOM_desktop_NEXT_RightColumnHalfPage_Prebid"} },
        { bidder: 'smartadserver', params: { siteId: 596129, pageId: 1823641, formatId: 115311}},
        //   { bidder: 'taboola', params: { tagId: 'RightColumn', publisherId: '1490051' }},
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' }},
        { bidder: 'sharethrough', params: {pkey: '4l3moi7W8L2m9tg8eh8zWr68'} },
        { bidder: 'sharethrough', params: {pkey: 'wqpzq09d3EDK0PYa2u1wg2gL'} },
        { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
      ]
    },
  ];
}
