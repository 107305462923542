import { isBrowser, checkUtmSourceStartsWith } from "../../utils";

export function slots() {
  // Need for build version as window undefined
  if (!isBrowser()) {
    return [];
  }

  // The current template
  const template = "NEXT";

  // Define Ad Unit Pathways
  let RightColumnPathway = `${template}_RightColumnHalfPage1`;
  let LeaderBelowTitlePathway = `${template}_Leader_BellowTitle`;

  // prettier-ignore
  const bidders = [
    { bidder: 'adagio', params: { organizationId: '1067', site: 'sportaroo-com', adUnitElementId: 'LeaderBelowTitle', environment: 'desktop', placement: `${LeaderBelowTitlePathway}` } },
    { bidder: 'adform', params: {mid: 1698814}},
    // //{ bidder: 'appnexus', params: {placementId: '20942283'}},
    // //{ bidder: 'justpremium', params: {zone: 115712, allow: ["is"]}},
    // //{ bidder: 'districtm',  params: {  placementId: 16005275 } },
    // //{ bidder: 'districtmDMX',  params: { dmxid: 367604, memberid : 101868 } },
    { bidder: 'medianet', params: {cid: '8CUJF5HOT', crid: '273381276'}},
    { bidder: 'openx', params: {unit: '559450479', delDomain: 'lqr-d.openx.net'}},
    { bidder: 'onetag', params: {pubId: '654b33df39ee492'}},
    { bidder: 'pubmatic', params: {publisherId: '159940', adSlot: '5172555'}},
    // //{ bidder: 'richaudience', params: { pid: 'QReieyrtGC', supplyType: 'site' }},
    { bidder: 'sovrn', params: {tagid: 1141120}},
    // { bidder: 'teads', params: { pageId: '113724', placementId: '135706'} },
    // { bidder: 'triplelift', params: {inventoryCode: "TrendscatchersCOM_NEXT_Leader_bellowTitle_Prebid"}},
    { bidder: 'smartadserver', params: { siteId: 596129, pageId: 1823641, formatId: 115311}},
    // { bidder: 'taboola', params: { tagId: 'LeaderBelowTitle', publisherId: '1490051' }},
    { bidder: 'rise', params: { org: '62050c0300be360001986a59' }},
    { bidder: 'sharethrough', params: {pkey: 'TsmLETM8253FHmHgQMUPQjth'} },
    { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
  ];

  // prettier-ignore
  const mobileBidders = [
    { bidder: 'adagio', params: { organizationId: '1067', site: 'sportaroo-com', adUnitElementId: 'LeaderBelowTitle', environment: 'mobile', placement: `${LeaderBelowTitlePathway}` } },
    { bidder: 'adform', params: {mid: 1698814}},
    // //{ bidder: 'appnexus', params: {placementId: '20942283'}},
    // //{ bidder: 'districtm',  params: { placementId: 16005275 } },
    // //{ bidder: 'districtmDMX',  params: { dmxid: 367604, memberid : 101868 } },
    // //{ bidder: 'justpremium', params: {zone: 115712, allow: ["is"]}},
    { bidder: 'medianet', params: {cid: '8CUJF5HOT', crid: '273381276'}},
    { bidder: 'onetag', params: {pubId: '654b33df39ee492'}},
    { bidder: 'openx', params: {unit: '559450479', delDomain: 'lqr-d.openx.net'}},
    { bidder: 'pubmatic', params: {publisherId: '159940', adSlot: '5172555'}},
    // //{ bidder: 'richaudience', params: { pid: '6yQQzMZSfj', supplyType: 'site' }},
    { bidder: 'sovrn', params: {tagid: 1141156}},
    // //{ bidder: 'sublime', params:{ zoneId: 33294 , notifyId: (function b(a){return a?(a^Math.random()*16>>a/4).toString(16):([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g,b)})(); return window.sublime.notifyId;})() } },
    // { bidder: 'teads', params: {pageId: '121182', placementId: '135710'}},
    // { bidder: 'triplelift', params: {inventoryCode: "TrendscatchersCOM_NEXT_Leader_bellowTitle_Prebid"}},
    { bidder: 'smartadserver', params: { siteId: 596129, pageId: 1823641, formatId: 115311}},
    // { bidder: 'taboola', params: { tagId: 'LeaderBelowTitle', publisherId: '1490051' }},
    { bidder: 'rise', params: { org: '62050c0300be360001986a59' }},
    { bidder: 'sharethrough', params: {pkey: 'is2AFla8jFP8tl4utqaP1Mu3'} },
    { bidder: 'sharethrough', params: {pkey: 'nd6k3hbuwgTBiJnzh1Ho8j9u'} },
    { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
  ];

  // prettier-ignore
  return [
    {
      "condition": !checkUtmSourceStartsWith('twtr_') && !checkUtmSourceStartsWith('yahoo_'),
      "id": "LeaderBelowTitle",
      "name": "NEXT_Leader_BellowTitle",
      "path": "/76730613/NEXT_Leader_BellowTitle",
      "sizes": window.screen.width < 1024 ? [[728, 90]] : [[970, 90]],
      "bidders": bidders,
      "mobile_bidders": mobileBidders,
    },
    {
      "condition": !checkUtmSourceStartsWith('twtr_') && !checkUtmSourceStartsWith('yahoo_') && window.screen.width < 812,
      "id": "LeaderBelowTitle_1",
      "name": "NEXT_Leader_BellowTitle",
      "path": "/76730613/NEXT_Leader_BellowTitle",
      "sizes": window.screen.width < 812 ? [[300, 250],[336,280],[320,100]] : [[970, 90]],
      "mobile_bidders": mobileBidders,
    },
    {
      "condition": !checkUtmSourceStartsWith('twtr_') && !checkUtmSourceStartsWith('yahoo_'),
      "id": "LeaderBelowTitle_2",
      "name": "NEXT_Leader_BellowTitle",
      "path": "/76730613/NEXT_Leader_BellowTitle",
      "sizes": window.screen.width < 812 ? [[300, 250],[336,280],[320,100]] : [[970, 90]],
      "bidders": bidders,
      "mobile_bidders": mobileBidders,
    },
    {
      "condition": !checkUtmSourceStartsWith('twtr_') && !checkUtmSourceStartsWith('yahoo_'),
      "id": "LeaderBelowTitle_4",
      "name": "NEXT_Leader_BellowTitle",
      "path": "/76730613/NEXT_Leader_BellowTitle",
      "sizes": window.screen.width < 812 ? [[300, 250],[336,280],[320,100]] : [[970, 90]],
      "bidders": bidders,
      "mobile_bidders": mobileBidders,
    },
    {
      "condition": window.screen.width > 812,
      "id": "RightColumn",
      "name": "NEXT_RightColumnHalfPage1",
      "path": "/76730613/NEXT_RightColumnHalfPage1",
      "sizes": window.screen.width > 1440 ? [[300, 600]] : [[160, 600]],
      "bidders": [
        { bidder: 'adagio', params: { organizationId: '1067', site: 'sportaroo-com', adUnitElementId: 'RightColumn', environment: 'desktop', placement: `${RightColumnPathway}` } },
        { bidder: 'adform', params: {mid: 1698845}},
        { bidder: 'sovrn', params: {tagid: 1141137}},
        { bidder: 'sovrn', params: {tagid: 1141138}},
        { bidder: 'sovrn', params: {tagid: 1141139}},
        //  // { bidder: 'appnexus', params: {placementId: '20942290'}},
        //   //{ bidder: 'districtm', params: { placementId: 16005296 } },
        //   //{ bidder: 'districtmDMX', params: { dmxid: 367608, memberid : 101868 } },
        //   //{ bidder: 'justpremium', params: {zone: 115712, allow: ["is"]}},
        { bidder: 'medianet', params: {cid: '8CUJF5HOT', crid: '548276061'}},
        { bidder: 'onetag', params: {pubId: '654b33df39ee492'}},
        { bidder: 'openx', params: {unit: '559450484', delDomain: 'lqr-d.openx.net'}},
        { bidder: 'pubmatic', params: {publisherId: '159940', adSlot: '5172555'}},
        //   //{ bidder: 'richaudience', params: { pid: 'HOpAKTXHmc', supplyType: 'site' }},
        //   { bidder: 'teads', params: {pageId: '121182', placementId: '131481'}},

        //   { bidder: 'triplelift', params: { inventoryCode: "TrendscatchersCOM_desktop_NEXT_RightColumnHalfPage_Prebid"} },
        { bidder: 'smartadserver', params: { siteId: 596129, pageId: 1823641, formatId: 115311}},
        //   { bidder: 'taboola', params: { tagId: 'RightColumn', publisherId: '1490051' }},
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' }},
        { bidder: 'sharethrough', params: {pkey: '4l3moi7W8L2m9tg8eh8zWr68'} },
        { bidder: 'sharethrough', params: {pkey: 'wqpzq09d3EDK0PYa2u1wg2gL'} },
        { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
      ]
    }
  ];
}
