import { isBrowser, checkUtmSourceStartsWith } from "../../utils";

export function slots() {
  // Need for build version as window undefined
  if (!isBrowser()) {
    return [];
  }

  // Define Ad unit names
  let RightColumnUnitName = "RightColumn1";
  let LeaderDesktopInfiniteUnitName = "LeaderDesktopInfinite";
  let MPUParallaxUnitName = "MPU_Parallax";
  let MPUCenterRightUnitName = "MPUCenterRight";
  let MPUTopRightUnitName = "MPUTopRight";
  let LeaderBelowTitleUnitName = "Leader_BellowTitle";
  let StickyBannerUnitName = "StickyBannerDesktop";
  if (window.screen.width < 812) {
    StickyBannerUnitName = "StickyBannerMobile";
  }

  // Define Ad Unit Pathways
  let RightColumnPathway = "RightColumn1";
  let LeaderDesktopInfinitePathway = "LeaderDesktopInfinite";
  let MPUParallaxPathway = "MPU_Parallax";
  let MPUCenterRightPathway = "MPUCenterRight";
  let MPUTopRightPathway = "MPUTopRight";
  let LeaderBelowTitlePathway = "Leader_BellowTitle";
  let StickyBannerPathway = "StickyBannerDesktop";
  if (window.screen.width < 812) {
    StickyBannerPathway = "StickyBannerMobile";
  }

  // prettier-ignore
  let adUnits = [
    {
      "condition": window.screen.width > 990 && !checkUtmSourceStartsWith('fb-') && !checkUtmSourceStartsWith('twtr_'),
      "id": "RightColumn",
      "name": `${RightColumnUnitName}`,
      "path": `/76730613/${RightColumnPathway}`,
      "sizes": window.screen.width > 1100 ? [[300,250], [336,280], [320,100], [320,50], [300,100], [300,50], [300,600], [160, 600]] : [[160, 600]],
      "bidders": [
        { bidder: 'adagio', params: { organizationId: '1067', site: 'sportaroo-com', adUnitElementId: 'RightColumn', environment: 'desktop', placement: `${RightColumnPathway}` } },
        // //{ bidder: 'appnexus', params: {placementId: '13715698'}},
        { bidder: 'adform', params: {mid: 1698846}},
        { bidder: 'criteo', params: {networkId: 8147}},
        // { bidder: 'ix', params: {siteId: 612746, size: [160, 600]}},
        // // { bidder: 'justpremium', params: {zone: 115712}},
        { bidder: 'medianet', params: {cid: '8CUJF5HOT', crid: '548276061'}},
        { bidder: 'onetag', params: {pubId: '654b33df39ee492'}},
        { bidder: 'openx', params: {unit: '559450469', delDomain: 'lqr-d.openx.net'}},
        { bidder: 'pubmatic', params: {publisherId: '159940', adSlot: '5172555'}},
        // //{ bidder: 'richaudience', params: { pid: 'CGbrucxlFe', supplyType: 'site' }},
        { bidder: 'sharethrough', params: {pkey: 'TJbndpWi3rV9pnenEZt1XxdP'} },
        { bidder: 'sharethrough', params: {pkey: '5BeBUaXGNgVKkJXbLdmx4gzw'} },
        { bidder: 'sovrn', params: {tagid: 1141142}},
        // { bidder: 'teads', params: {pageId: '121182', placementId: '131481'}},
        // //  { bidder: 'triplelift', params: {inventoryCode: "TrendscatchersCOM_RightColumnHalfPage_Prebid"} }
        // { bidder: 'triplelift', params: {inventoryCode: " TrendscatchersCOM_desktop_RightColumnHalfPage_Prebid"}},
        { bidder: 'smartadserver', params: { siteId: 596129, pageId: 1823641, formatId: 115311}},
        // { bidder: 'taboola', params: { tagId: 'RightColumn', publisherId: '1490051' }},
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' }},
        { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
      ],
    },
    {
      "condition": checkStickybanner(),
      "id": "StickyBanner",
      "name": StickyBannerUnitName,
      "path": `/76730613/${StickyBannerPathway}`,
      "sizes": window.screen.width < 812 ? [[320, 50],[300,50],[360,50],[320,100],[300,100],[360,100]] : [[728, 90]],
      "bidders": [
        { bidder: 'adagio', params: { organizationId: '1067', site: 'sportaroo-com', adUnitElementId: 'StickyBanner', environment: 'desktop', placement: `${StickyBannerPathway}` } },
        { bidder: 'adform', params: {mid: 1698850}},
        // { bidder: 'ix', params: {siteId: 697607, size: [728, 90]}},
        { bidder: 'sovrn', params: {tagid: 1141144}},
        // //{ bidder: 'appnexus', params: {placementId: '22129022'}},
        { bidder: 'pubmatic', params: {publisherId: '159940', adSlot: '5172555'}},
        // { bidder: 'triplelift', params: {inventoryCode: "TrendscatchersCOM_desktop_StickyBannerDesktop_Prebid"}},
        { bidder: 'criteo', params: {networkId: 8147}},
        // { bidder: 'justpremium', params: {zone: 115712}},
        { bidder: 'medianet', params: {cid: '8CUJF5HOT', crid: '678524856'}},
        { bidder: 'onetag', params: {pubId: '654b33df39ee492'}},
        { bidder: 'openx', params: {unit: '559450472', delDomain: 'lqr-d.openx.net'}},
        // //{ bidder: 'richaudience', params: { pid: '0scZyiMm8e', supplyType: 'site' }},
        // { bidder: 'teads', params: {pageId: '121182', placementId: '131481'}},
        { bidder: 'smartadserver', params: { siteId: 596129, pageId: 1823641, formatId: 115311}},
        // { bidder: 'taboola', params: { tagId: 'StickyBanner', publisherId: '1490051' }},
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' }},
        { bidder: 'sharethrough', params: {pkey: 'y49zNq8pHov1IL1zde1Ewq8J'} },
        { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
      ],
      "mobile_bidders": [
        { bidder: 'adagio', params: { organizationId: '1067', site: 'sportaroo-com', adUnitElementId: 'StickyBanner', environment: 'mobile', placement: `${StickyBannerPathway}` } },
        // //{ bidder: 'appnexus', params: {placementId: '18069675'}},
        { bidder: 'adform', params: {mid: 1698836}},
        { bidder: 'criteo', params: {networkId: 8147}},
        // { bidder: 'ix', params: {siteId: 612758, size: [320, 50]}},
        // { bidder: 'ix', params: {siteId: 612758, size: [360, 50]}},
        // { bidder: 'ix', params: {siteId: 612758, size: [300, 50]}},
        // { bidder: 'justpremium', params: {zone: 115712, allow: ["is"]}},
        { bidder: 'medianet', params: {cid: '8CUJF5HOT', crid: '874442853'}},
        { bidder: 'onetag', params: {pubId: '654b33df39ee492'}},
        { bidder: 'openx', params: {unit: '559450474', delDomain: 'lqr-d.openx.net'}},
        { bidder: 'pubmatic', params: {publisherId: '159940', adSlot: '5172555'}},
        // //{ bidder: 'richaudience', params: { pid: '0scZyiMm8e', supplyType: 'site' }},
        { bidder: 'sovrn', params: {tagid: 1141174}},
        // { bidder: 'teads', params: {pageId: '121182', placementId: '131481'}},
        // { bidder: 'triplelift', params: {inventoryCode: "TrendscatchersCOM_StickyBannerMobile_Prebid"}},
        { bidder: 'smartadserver', params: { siteId: 596129, pageId: 1823641, formatId: 115311}},
        // { bidder: 'taboola', params: { tagId: 'StickyBanner', publisherId: '1490051' }},
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' }},
        { bidder: 'sharethrough', params: {pkey: 'UAP10roDUJYMYdPxy9mGRr1j'} },
        // { bidder: 'sharethrough', params: {pkey: 'Q2bXKT9WxQoMmOdT9ostGM1o'} },
        // { bidder: 'sharethrough', params: {pkey: '5xzshoKLcAwUwUGOp3ReiJAx'} }
        { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
      ]
    },
    {
      "condition": window.screen.width < 812,
      "id": "MPUCenterRight",
      "name": MPUCenterRightUnitName,
      "path": `/76730613/${MPUCenterRightPathway}`,
      "sizes": [[300, 250],[336,280],[320,100],[1,1]],
      "mobile_bidders": [
        { bidder: 'adagio', params: { organizationId: '1067', site: 'sportaroo-com', adUnitElementId: 'MPUCenterRight', environment: 'mobile', placement: `${MPUCenterRightPathway}` } },
        //  // { bidder: 'appnexus', params: {placementId: '13715695'}},
        { bidder: 'adform', params: {mid: 1698834}},
        { bidder: 'criteo', params: {networkId: 8147}},
        //   { bidder: 'ix', params: {siteId: 612753, size: [300, 250]}},
        //   { bidder: 'ix', params: {siteId: 612753, size: [336, 280]}},
        //   { bidder: 'ix', params: {siteId: 538242, size: [320, 100]}},
        //   { bidder: 'justpremium', params: {zone: 115712, allow: ["is"]}},
        { bidder: 'medianet', params: {cid: '8CUJF5HOT', crid: '213381248'}},
        { bidder: 'onetag', params: {pubId: '654b33df39ee492'}},
        { bidder: 'openx', params: {unit: '559450478', delDomain: 'lqr-d.openx.net'}},
        { bidder: 'pubmatic', params: {publisherId: '159940', adSlot: '5172555'}},
        //   //{ bidder: 'richaudience', params: { pid: 'DxgLRqBxjs', supplyType: 'site' }},
        { bidder: 'sovrn', params: {tagid: 1141146}},
        //   { bidder: 'teads', params: {pageId: '121182', placementId: '135713'}},
        //   { bidder: 'triplelift', params: {inventoryCode: "TrendscatchersCOM_MPUCenterRight_Prebid"}},
        { bidder: 'smartadserver', params: { siteId: 596129, pageId: 1823641, formatId: 115311}},
        //   { bidder: 'taboola', params: { tagId: 'MPUCenterRight', publisherId: '1490051' }},
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' }},
        { bidder: 'sharethrough', params: {pkey: 'oDgSUxtzR6ygelQiH1eC1UAe'} },
        { bidder: 'sharethrough', params: {pkey: 'ZH3K5BhvaRdtMB1OZOsdGwEw'} },
        { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
      ]
    },
    {
      "condition": window.screen.width > 812,
      "id": "LeaderDesktopInfinite",
      "name":  LeaderDesktopInfiniteUnitName,
      "path": `/76730613/${LeaderDesktopInfinitePathway}`,
      "sizes": [[728,90]],
      "bidders": [
        { bidder: 'adagio', params: { organizationId: '1067', site: 'sportaroo-com', adUnitElementId: 'LeaderDesktopInfinite', environment: 'desktop', placement: `${LeaderDesktopInfinitePathway}` } },
        //  // { bidder: 'appnexus', params: {placementId: '20295574'}},
        { bidder: 'adform', params: {mid: 1698847}},
        { bidder: 'criteo', params: {networkId: 8147}},
        //   { bidder: 'ix', params: {siteId: 639600, size: [728, 90]}},
        //   //{ bidder: 'ix', params: { siteId: 538242, size: [300, 100] }},
        //   // { bidder: 'justpremium', params: {zone: 115712}},
        //   //{ bidder: 'districtm', params: { placementId: 19614578}},
        { bidder: 'medianet', params: {cid: '8CUJF5HOT', crid: '915879418'}},
        { bidder: 'onetag', params: {pubId: '654b33df39ee492'}},
        { bidder: 'openx', params: {unit: '559450468', delDomain: 'lqr-d.openx.net'}},
        { bidder: 'pubmatic', params: {publisherId: '159940', adSlot: '5172555'}},
        //   //{ bidder: 'richaudience', params: { pid: 'EOOmDSzb4r', supplyType: 'site' }},
        { bidder: 'sovrn', params: {tagid: 1141096}},
        //   { bidder: 'teads', params: {pageId: '121182', placementId: '131481'}},
        //   { bidder: 'triplelift', params: {inventoryCode: "TrendscatchersCOM_Leader_bellowTitle_Prebid"}},
        { bidder: 'smartadserver', params: { siteId: 596129, pageId: 1823641, formatId: 115311}},
        //   { bidder: 'taboola', params: { tagId: 'LeaderDesktopInfinite', publisherId: '1490051' }},
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' }},
        { bidder: 'sharethrough', params: {pkey: '9qWVWWG33Fk5ZUknUqeQjpzy'} },
        { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
      ],
    },
    {
      "condition": false,
      "id": "MPU_Parallax",
      "name": MPUParallaxUnitName,
      "path": `/76730613/${MPUParallaxPathway}`,
      "sizes": [[336, 280], [320, 480], [300, 250], [300,600], [160,600], [120,600], [320,50]],
      "bidders": [
        { bidder: 'adagio', params: { organizationId:'1067', site: 'sportaroo-com',adUnitElementId: 'MPU_Parallax', environment:'mobile', placement:`${MPUParallaxPathway}` } },
        { bidder: 'adform', params: { mid: 1698860 } },
        { bidder: 'criteo', params: { networkId: 8147 } },
        // //  { bidder: 'appnexus', params: {placementId: '22738661'} },
        { bidder: 'medianet', params: {cid: '8CUJF5HOT', crid: '636231821'} },
        { bidder: 'openx', params: { unit: '559450475', delDomain: 'lqr-d.openx.net' } },
        //   { bidder: 'ix', params: {siteId: 715016, size: [300,250]} },
        //   { bidder: 'ix', params: {siteId: 715016, size: [320,480]} },
        //   { bidder: 'ix', params: {siteId: 715016, size: [300,250]} },
        //   { bidder: 'ix', params: {siteId: 715016, size: [300,600]} },
        //   { bidder: 'ix', params: {siteId: 715016, size: [160,600]} },
        //   { bidder: 'ix', params: {siteId: 715016, size: [320,50]} },
        { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        //   { bidder: 'openx', params: { unit: '542520989', delDomain: 'lqr-d.openx.net'}},
        { bidder: 'sovrn', params:{ tagid: 1141149 }},
        { bidder: 'sovrn', params:{ tagid: 1141150 }},
        { bidder: 'sovrn', params:{ tagid: 1141151 }},
        { bidder: 'sovrn', params:{ tagid: 1141152 }},
        { bidder: 'sovrn', params:{ tagid: 1141153 }},
        { bidder: 'sovrn', params:{ tagid: 1141095 }},
        { bidder: 'sovrn', params:{ tagid: 1141154 }},
        { bidder: 'sovrn', params:{ tagid: 1141155 }},
        { bidder: 'pubmatic', params: {publisherId: '159940', adSlot: '5172555'}},
        //   // { bidder: 'teads', params: { pageId: '121182', placementId: '135713'} },
        //   { bidder: 'teads', params: { pageId: '121182', placementId: '131481'} },
        //   { bidder: 'triplelift', params: {inventoryCode: 'TrendscatchersCOM_mobile_MPUParallax_Prebid'} },
        { bidder: 'smartadserver', params: { siteId: 596129, pageId: 1823641, formatId: 115311}},
        //   { bidder: 'taboola', params: { tagId: 'MPU_Parallax', publisherId: '1490051' }},
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' }},
        { bidder: 'sharethrough', params: {pkey: 'wXsyp582kXpoCPPxFb8vhiEM'} },
        { bidder: 'sharethrough', params: {pkey: 'peRJHbLTV6n5278wOxkoHCVV'} },
        { bidder: 'sharethrough', params: {pkey: 'hd26ywkEduBatAmYqEPPy8hZ'} },
        { bidder: 'sharethrough', params: {pkey: 'cHfxi0SVxfvh3r9ehWrUYnOG'} },
        { bidder: 'sharethrough', params: {pkey: 'huDbQP1mFnqIzO2xeCjGzVWY'} },
        { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
      ],
    },
    {
      "condition": window.screen.width < 812 && !checkUtmSourceStartsWith('twtr_') && !checkUtmSourceStartsWith('yahoo_') && !checkUtmSourceStartsWith('fb-') && !checkUtmSourceStartsWith('twtr_'),
      "id": "LeaderBelowTitle",
      "name": `${LeaderBelowTitleUnitName}`,
      "path": `/76730613/${LeaderBelowTitlePathway}`,
      "sizes": [[300, 250],[336,280],[320,100]],
      "mobile_bidders": [
        { bidder: 'adagio', params: { organizationId: '1067', site: 'sportaroo-com', adUnitElementId: 'LeaderBelowTitle', environment: 'mobile', placement: `${LeaderBelowTitlePathway}` } },
        //  // { bidder: 'appnexus', params: {placementId: '13715686'}},
        { bidder: 'adform', params: {mid: 1698833}},
        { bidder: 'criteo', params: {networkId: 8147}},
        //   { bidder: 'ix', params: {siteId: 612754, size: [300, 250]}},
        //   { bidder: 'ix', params: {siteId: 612754, size: [336, 280]}},
        //   { bidder: 'ix', params: {siteId: 538242, size: [320, 100]}},
        //  // { bidder: 'justpremium', params: {zone: 115712, allow: ["is"]}},
        { bidder: 'medianet', params: {cid: '8CUJF5HOT', crid: '915879418'}},
        { bidder: 'onetag', params: {pubId: '654b33df39ee492'}},
        { bidder: 'openx', params: {unit: '559450476', delDomain: 'lqr-d.openx.net'}},
        { bidder: 'pubmatic', params: {publisherId: '159940', adSlot: '5172555'}},
        //   //{ bidder: 'richaudience', params: { pid: 'yuEeeriz0A', supplyType: 'site' }},
        { bidder: 'sovrn', params: {tagid: 1141145}},
        //   { bidder: 'teads', params: {pageId: '121182', placementId: '135713'}},
        //   { bidder: 'triplelift', params: {inventoryCode: "TrendscatchersCOM_Leader_bellowTitle_Prebid"}},
        { bidder: 'smartadserver', params: { siteId: 596129, pageId: 1823641, formatId: 115311}},
        //   { bidder: 'taboola', params: { tagId: 'LeaderBelowTitle', publisherId: '116591' }},
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' }},
        { bidder: 'sharethrough', params: {pkey: '92ciowh5dO9ZQ4DV9b1jvumZ'} },
        { bidder: 'sharethrough', params: {pkey: 'duirGscmHqfV3BwzkiAnONLr'} },
        { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
      ]
    },
    {
      "condition": window.screen.width < 812 && !checkUtmSourceStartsWith('fb-') && !checkUtmSourceStartsWith('twtr_'),
      "id": "MPUTopRight",
      "name": `${MPUTopRightUnitName}`,
      "path": `/76730613/${MPUTopRightPathway}`,
      "sizes": [[300, 250],[336,280],[320,100]],
      "mobile_bidders": [
        { bidder: 'adagio', params: { organizationId: '1067', site: 'sportaroo-com', adUnitElementId: 'MPUTopRight', environment: 'mobile', placement: `${MPUTopRightPathway}` } },
        // //{ bidder: 'appnexus', params: {placementId: '13715693'}},
        { bidder: 'adform', params: {mid: 1698835}},
        { bidder: 'criteo', params: {networkId: 8147}},
        // { bidder: 'ix', params: {siteId: 612750, size: [300, 250]}},
        // { bidder: 'ix', params: {siteId: 612750, size: [336, 280]}},
        // { bidder: 'ix', params: {siteId: 538242, size: [320, 100]}},
        // //{ bidder: 'justpremium', params: {zone: 115712, allow: ["is"]}},
        { bidder: 'medianet', params: {cid: '8CUJF5HOT', crid: '339895831'}},
        { bidder: 'onetag', params: {pubId: '654b33df39ee492'}},
        { bidder: 'openx', params: {unit: '559450477', delDomain: 'lqr-d.openx.net'}},
        { bidder: 'pubmatic', params: {publisherId: '159940', adSlot: '5172555'}},
        // //{ bidder: 'richaudience', params: { pid: '94tKaTDqO9', supplyType: 'site' }},
        { bidder: 'sovrn', params: {tagid: 1141148}},
        // { bidder: 'teads', params: {pageId: '121182', placementId: '135713'}},
        // { bidder: 'triplelift', params: {inventoryCode: "TrendscatchersCOM_MPUTopRight_Prebid"}},
        { bidder: 'smartadserver', params: { siteId: 596129, pageId: 1823641, formatId: 115311}},
        // { bidder: 'taboola', params: { tagId: 'MPUTopRight', publisherId: '1490051' }},
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' }},
        { bidder: 'sharethrough', params: {pkey: '6vQZI66PZlI0wkF45RUAjl8f'} },
        { bidder: 'sharethrough', params: {pkey: 'R01OUNoxV9ArFkU2Pk73ALjc'} },
        { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
      ]

    },
  ];

  /**
   * Finds left and right columns, pushes the missing bidders
   * and pushes correct bidders to the adunit
   */
  // prettier-ignore
  if (window.screen.width >= 1100) {
      let rcMissingLines = adUnits.find(ad => ad.id === 'RightColumn');
      rcMissingLines.bidders.push({ bidder: 'sharethrough', params: {pkey: 'zuddLxsoJ9jPF2Dfe72LttQA'} }); // -----
      rcMissingLines.bidders.push({ bidder: 'sovrn', params: {tagid: 1141141}});
    }
  return adUnits;

  function checkStickybanner() {
    if (
      !(checkUtmSourceStartsWith("fb-") && !checkUtmSourceStartsWith("twtr_"))
    ) {
      return window.screen.width < 812;
    } else {
      return false;
    }
  }
}
