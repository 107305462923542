import { isBrowser, checkUtmSourceStartsWith } from "../../utils";

export function slots() {
  // Need for build version as window undefined
  if (!isBrowser()) {
    return [];
  }

  // The current template
  const template = "NEXT";

  // Define Ad unit names
  // Original
  let RightColumnUnitName = `${template}_RightColumnHalfPage1`;
  let LeftColumnUnitName = `${template}_LeftColumnHalfPage`;
  let MPUCenterRightUnitName = `${template}_MPUCenterRight`;
  let MPUTopRightUnitName = `${template}_MPUTopRight`;
  let LeaderBelowTitleUnitName = `${template}_Leader_BellowTitle`;
  let StickyBannerUnitName = `${template}_StickyBannerDesktop`;
  let MPUBelowNextUnitName = `${template}_MPUBelowNext`;
  if (window.screen.width < 812) {
    StickyBannerUnitName = `${template}_StickyBannerMobile`;
  }

  // Define Ad Unit Pathways
  let RightColumnPathway = `${template}_RightColumnHalfPage1`;
  let LeftColumnPathway = `${template}_LeftColumnHalfPage`;
  let MPUCenterRightPathway = `${template}_MPUCenterRight`;
  let MPUTopRightPathway = `${template}_MPUTopRight`;
  let LeaderBelowTitlePathway = `${template}_Leader_BellowTitle`;
  let StickyBannerPathway = `${template}_StickyBannerDesktop`;
  let MPUBelowNextPathway = `${template}_MPUBelowNext`;
  if (window.screen.width < 812) {
    StickyBannerPathway = `${template}_StickyBannerMobile`;
  }

  // prettier-ignore
  let adUnits = [
      {
        "condition": !checkUtmSourceStartsWith('twtr_') && !checkUtmSourceStartsWith('yahoo_'),
        "id": "LeaderBelowTitle",
        "name": LeaderBelowTitleUnitName,
        "path": `/76730613/${LeaderBelowTitlePathway}`,
        "sizes": window.screen.width < 812 ? [[300, 250],[336,280],[320,100]] : [[728, 90]],
        "bidders": [
          { bidder: 'adagio', params: { organizationId: '1067', site: 'sportaroo-com', adUnitElementId: 'LeaderBelowTitle', environment: 'desktop', placement: `${LeaderBelowTitlePathway}` } },
          { bidder: 'adform', params: {mid: 1698814}},
          // //{ bidder: 'appnexus', params: {placementId: '20942283'}},
          { bidder: 'criteo', params: {networkId: 8147}},
          // { bidder: 'ix', params: {siteId: 623506, size: [728, 90]}},
          // //{ bidder: 'justpremium', params: {zone: 115712, allow: ["is"]}},
          // //{ bidder: 'districtm',  params: {  placementId: 16005275 } },
          // //{ bidder: 'districtmDMX',  params: { dmxid: 367604, memberid : 101868 } },
          { bidder: 'medianet', params: {cid: '8CUJF5HOT', crid: '273381276'}},
          { bidder: 'openx', params: {unit: '559450479', delDomain: 'lqr-d.openx.net'}},
          { bidder: 'onetag', params: {pubId: '654b33df39ee492'}},
          { bidder: 'pubmatic', params: {publisherId: '159940', adSlot: '5172555'}},
          // //{ bidder: 'richaudience', params: { pid: 'QReieyrtGC', supplyType: 'site' }},
          { bidder: 'sovrn', params: {tagid: 1141120}},
          // { bidder: 'teads', params: { pageId: '113724', placementId: '135706'} },
          // { bidder: 'triplelift', params: {inventoryCode: "TrendscatchersCOM_NEXT_Leader_bellowTitle_Prebid"}},
          { bidder: 'smartadserver', params: { siteId: 596129, pageId: 1823641, formatId: 115311}},
          // { bidder: 'taboola', params: { tagId: 'LeaderBelowTitle', publisherId: '1490051' }},
          { bidder: 'rise', params: { org: '62050c0300be360001986a59' }},
          { bidder: 'sharethrough', params: {pkey: 'TsmLETM8253FHmHgQMUPQjth'} },
          { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
        ],
        "mobile_bidders": [
          { bidder: 'adagio', params: { organizationId: '1067', site: 'sportaroo-com', adUnitElementId: 'LeaderBelowTitle', environment: 'mobile', placement: `${LeaderBelowTitlePathway}` } },
          { bidder: 'adform', params: {mid: 1698814}},
          // //{ bidder: 'appnexus', params: {placementId: '20942283'}},
          { bidder: 'criteo', params: {networkId: 8147}},
          // //{ bidder: 'districtm',  params: { placementId: 16005275 } },
          // //{ bidder: 'districtmDMX',  params: { dmxid: 367604, memberid : 101868 } },
          // { bidder: 'ix', params: {siteId: 623528, size: [300, 250]}},
          // { bidder: 'ix', params: {siteId: 623528, size: [336, 280]}},
          // { bidder: 'ix', params: {siteId: 623528, size: [320, 100]}},
          // //{ bidder: 'justpremium', params: {zone: 115712, allow: ["is"]}},
          { bidder: 'medianet', params: {cid: '8CUJF5HOT', crid: '273381276'}},
          { bidder: 'onetag', params: {pubId: '654b33df39ee492'}},
          { bidder: 'openx', params: {unit: '559450479', delDomain: 'lqr-d.openx.net'}},
          { bidder: 'pubmatic', params: {publisherId: '159940', adSlot: '5172555'}},
          // //{ bidder: 'richaudience', params: { pid: '6yQQzMZSfj', supplyType: 'site' }},
          { bidder: 'sovrn', params: {tagid: 1141156}},
          // //{ bidder: 'sublime', params:{ zoneId: 33294 , notifyId: (function b(a){return a?(a^Math.random()*16>>a/4).toString(16):([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g,b)})(); return window.sublime.notifyId;})() } },
          // { bidder: 'teads', params: {pageId: '121182', placementId: '135710'}},
          // { bidder: 'triplelift', params: {inventoryCode: "TrendscatchersCOM_NEXT_Leader_bellowTitle_Prebid"}},
          { bidder: 'smartadserver', params: { siteId: 596129, pageId: 1823641, formatId: 115311}},
          // { bidder: 'taboola', params: { tagId: 'LeaderBelowTitle', publisherId: '1490051' }},
          { bidder: 'rise', params: { org: '62050c0300be360001986a59' }},
          { bidder: 'sharethrough', params: {pkey: 'is2AFla8jFP8tl4utqaP1Mu3'} },
          { bidder: 'sharethrough', params: {pkey: 'nd6k3hbuwgTBiJnzh1Ho8j9u'} },
          { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
        ]
      },
      {
        "condition": window.screen.width < 812,
        "id": "MPUTopRight",
        "name": MPUTopRightUnitName,
        "path": `/76730613/${MPUTopRightPathway}`,
        "sizes": [[300, 250],[336,280],[320,100]],
        "mobile_bidders": [
          { bidder: 'adagio', params: { organizationId: '1067', site: 'sportaroo-com', adUnitElementId: 'MPUTopRight', environment: 'mobile', placement: `${MPUTopRightPathway}` } },
          { bidder: 'adform', params: {mid: 1698817}},
          // //{ bidder: 'appnexus', params: {placementId: '20942284'}},
          { bidder: 'criteo', params: {networkId: 8147}},
          // //{ bidder: 'districtm', params: { placementId: 16005279 } },
          // //{ bidder: 'districtmDMX', params: { dmxid: 367605, memberid : 101868 } },
          // { bidder: 'ix', params: {siteId: 623512, size: [300, 250]}},
          // { bidder: 'ix', params: {siteId: 623512, size: [336, 280]}},
          // { bidder: 'ix', params: {siteId: 623512, size: [320, 100]}},
          // //{ bidder: 'justpremium', params: {zone: 115712, allow: ["is"]}},
          { bidder: 'medianet', params: {cid: '8CUJF5HOT', crid: '339895831'}},
          { bidder: 'onetag', params: {pubId: '654b33df39ee492'}},
          { bidder: 'openx', params: {unit: '559450480', delDomain: 'lqr-d.openx.net'}},
          { bidder: 'pubmatic', params: {publisherId: '159940', adSlot: '5172555'}},
          // //{ bidder: 'richaudience', params: { pid: 'hoS2bUVNvb', supplyType: 'site' }},
          { bidder: 'sovrn', params: {tagid: 1141159}},
          // //{ bidder: 'sublime', params:{ zoneId: 33294 , notifyId: (function b(a){return a?(a^Math.random()*16>>a/4).toString(16):([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g,b)})(); return window.sublime.notifyId;})() } },
          // { bidder: 'teads', params: {pageId: '121182', placementId: '135711'}},
          // { bidder: 'triplelift', params: {inventoryCode: "TrendscatchersCOM_NEXT_MPUTopRight_Prebid"}},
          { bidder: 'smartadserver', params: { siteId: 596129, pageId: 1823641, formatId: 115311}},
          // { bidder: 'taboola', params: { tagId: 'MPUTopRight', publisherId: '1490051' }},
          { bidder: 'rise', params: { org: '62050c0300be360001986a59' }},
          { bidder: 'sharethrough', params: {pkey: 'W33OPwhqJzU715k6MqrjhusJ'} },
          { bidder: 'sharethrough', params: {pkey: 'S9G7SluVDCwKOYqsLu2Q46c0'} },
          { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
        ]
      },
      {
        "condition": window.screen.width > 812,
        "id": "RightColumn",
        "name": RightColumnUnitName,
        "path": `/76730613/${RightColumnPathway}`,
        "sizes": window.screen.width > 1440 ? [[300, 600], [160, 600], [300, 250]] : [[160, 600]],
        "bidders": [
          { bidder: 'adagio', params: { organizationId: '1067', site: 'sportaroo-com', adUnitElementId: 'RightColumn', environment: 'desktop', placement: `${RightColumnPathway}` } },
          { bidder: 'adform', params: {mid: 1698845}},
          { bidder: 'sovrn', params: {tagid: 1141137}},
          { bidder: 'sovrn', params: {tagid: 1141138}},
          { bidder: 'sovrn', params: {tagid: 1141139}},
          //  // { bidder: 'appnexus', params: {placementId: '20942290'}},
          { bidder: 'criteo', params: {networkId: 8147}},
          //   //{ bidder: 'districtm', params: { placementId: 16005296 } },
          //   //{ bidder: 'districtmDMX', params: { dmxid: 367608, memberid : 101868 } },
          //   { bidder: 'ix', params: {siteId: 623502, size: [160, 600]}},
          //   //{ bidder: 'justpremium', params: {zone: 115712, allow: ["is"]}},
          { bidder: 'medianet', params: {cid: '8CUJF5HOT', crid: '548276061'}},
          { bidder: 'onetag', params: {pubId: '654b33df39ee492'}},
          { bidder: 'openx', params: {unit: '559450484', delDomain: 'lqr-d.openx.net'}},
          { bidder: 'pubmatic', params: {publisherId: '159940', adSlot: '5172555'}},
          //   //{ bidder: 'richaudience', params: { pid: 'HOpAKTXHmc', supplyType: 'site' }},
          //   { bidder: 'teads', params: {pageId: '121182', placementId: '131481'}},

          //   { bidder: 'triplelift', params: { inventoryCode: "TrendscatchersCOM_desktop_NEXT_RightColumnHalfPage_Prebid"} },
          { bidder: 'smartadserver', params: { siteId: 596129, pageId: 1823641, formatId: 115311}},
          //   { bidder: 'taboola', params: { tagId: 'RightColumn', publisherId: '1490051' }},
          { bidder: 'rise', params: { org: '62050c0300be360001986a59' }},
          { bidder: 'sharethrough', params: {pkey: '4l3moi7W8L2m9tg8eh8zWr68'} },
          { bidder: 'sharethrough', params: {pkey: 'wqpzq09d3EDK0PYa2u1wg2gL'} },
          { bidder: 'sharethrough', params: {pkey: '7riXt89OD94iJ311yhSHquer'} },
          { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
        ]
      },
      {
        "condition": window.screen.width > 812 && !checkUtmSourceStartsWith('twtr_') && !checkUtmSourceStartsWith('yahoo_'),
        "id": "LeftColumn",
        "name": LeftColumnUnitName,
        "path": `/76730613/${LeftColumnPathway}`,
        "sizes": window.screen.width > 1440 ? [[300, 600], [160, 600], [300, 250]] : [[160, 600]],
        "bidders": [
          { bidder: 'adagio', params: { organizationId: '1067', site: 'sportaroo-com', adUnitElementId: 'LeftColumn', environment: 'desktop', placement: `${LeftColumnPathway}` } },
          { bidder: 'adform', params: {mid: 1698852}},
          { bidder: 'criteo', params: {networkId: 347220}},
          // { bidder: 'ix', params: {siteId: 697578, size: [160, 600]}},
          { bidder: 'medianet', params: {cid: '8CUJF5HOT', crid: '548276061'}},
          { bidder: 'onetag', params: {pubId: '654b33df39ee492'}},
          { bidder: 'sovrn', params: {tagid: 1141121}},
          { bidder: 'sovrn', params: {tagid: 1141122}},
          { bidder: 'sovrn', params: {tagid: 1141123}},
          // //{ bidder: 'appnexus', params: {placementId: '22129020'}},
          { bidder: 'openx', params: {unit: '559450485', delDomain: 'lqr-d.openx.net'}},
          { bidder: 'pubmatic', params: {publisherId: '159940', adSlot: '5172555'}},
          // { bidder: 'teads', params: {pageId: '121182', placementId: '131481'}},
          { bidder: 'smartadserver', params: { siteId: 596129, pageId: 1823641, formatId: 115311}},
          // { bidder: 'taboola', params: { tagId: 'LeftColumn', publisherId: '1490051' }},
          { bidder: 'rise', params: { org: '62050c0300be360001986a59' }},
          { bidder: 'sharethrough', params: {pkey: 'itF7TgFm4VuqAvjhZFnG67HI'} },
          { bidder: 'sharethrough', params: {pkey: 'ZbVlc3BpzHpNXg1Or8DFwSY4'} },
          { bidder: 'sharethrough', params: {pkey: 'ggK60MHyxI01vDSwO3HwWp7e'} },
          { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
        ]
      },
      {
        "condition": !checkUtmSourceStartsWith('twtr_') && !checkUtmSourceStartsWith('yahoo_'),
        "id": "StickyBanner",
        "name": StickyBannerUnitName,
        "path": `/76730613/${StickyBannerPathway}`,
        "sizes": window.screen.width < 812 ? [[320, 50],[300,50],[360,50],[320,100],[300,100],[360,100]] : [[728, 90]],
        "bidders": [
          { bidder: 'adagio', params: { organizationId: '1067', site: 'sportaroo-com', adUnitElementId: 'StickyBanner', environment: 'desktop', placement: `${StickyBannerPathway}` } },
          { bidder: 'adform', params: {mid: 1698851}},
          // { bidder: 'ix', params: {siteId: 697608, size: [728, 90]}},
          { bidder: 'sovrn', params: {tagid: 1141140}},
          // //{ bidder: 'appnexus', params: {placementId: '22129021'}},
          { bidder: 'criteo', params: {networkId: 347220}},
          { bidder: 'medianet', params: {cid: '8CUJF5HOT', crid: '678524856'}},
          { bidder: 'onetag', params: {pubId: '654b33df39ee492'}},
          { bidder: 'openx', params: {unit: '559450486', delDomain: 'lqr-d.openx.net'}},
          // { bidder: 'justpremium', params: {zone: 115712}},
          { bidder: 'pubmatic', params: {publisherId: '159940', adSlot: '5172555'}},
          // { bidder: 'teads', params: {pageId: '121182', placementId: '131481'}},
          // { bidder: 'triplelift', params: {inventoryCode: "TrendscatchersCOM_desktop_NEXT_StickyBannerDesktop_Prebid"}},
          { bidder: 'smartadserver', params: { siteId: 596129, pageId: 1823641, formatId: 115311}},
          // { bidder: 'taboola', params: { tagId: 'StickyBanner', publisherId: '1490051' }},
          { bidder: 'rise', params: { org: '62050c0300be360001986a59' }},
          { bidder: 'sharethrough', params: {pkey: 'sfzCa5cyj5RRUOESRf7585g6'} },
          { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
        ],
        "mobile_bidders": [
          { bidder: 'adagio', params: { organizationId: '1067', site: 'sportaroo-com', adUnitElementId: 'StickyBanner', environment: 'mobile', placement: `${StickyBannerPathway}` } },
          { bidder: 'adform', params: {mid: 1698823}},
          //  // { bidder: 'appnexus', params: {placementId: '20942287'}},
          { bidder: 'criteo', params: {networkId: 8147}},
          //   //{ bidder: 'districtm', params: { placementId: 18069184} },
          //   //{ bidder: 'districtmDMX',params: {dmxid: 479166, memberid: 101868} },
          //   { bidder: 'ix', params: {siteId: 623542, size: [320, 50]}},
          //   { bidder: 'ix', params: {siteId: 623542, size: [300, 50]}},
          //   { bidder: 'ix', params: {siteId: 623542, size: [360, 50]}},
          //   { bidder: 'justpremium', params: {zone: 115712, allow: ["is"]}},
          { bidder: 'medianet', params: {cid: '8CUJF5HOT', crid: '874442853'}},
          { bidder: 'onetag', params: {pubId: '654b33df39ee492'}},
          { bidder: 'openx', params: {unit: '559450482', delDomain: 'lqr-d.openx.net'}},
          { bidder: 'pubmatic', params: {publisherId: '159940', adSlot: '5172555'}},
          //   //{ bidder: 'richaudience', params: { pid: '7DEfe5PFrk', supplyType: 'site' }},
          { bidder: 'sovrn', params: {tagid: 1141164}},
          //   //{ bidder: 'sublime', params:{ zoneId: 33294 , notifyId: (function b(a){return a?(a^Math.random()*16>>a/4).toString(16):([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g,b)})(); return window.sublime.notifyId;})() } },
          //   { bidder: 'teads', params: {pageId: '121182', placementId: '131481'}},
          //   { bidder: 'triplelift', params: {inventoryCode: "TrendscatchersCOM_NEXT_StickyBannerMobile_Prebid"}},
          { bidder: 'smartadserver', params: { siteId: 596129, pageId: 1823641, formatId: 115311}},
          //   { bidder: 'taboola', params: { tagId: 'StickyBanner', publisherId: '1490051' }},
          { bidder: 'rise', params: { org: '62050c0300be360001986a59' }},
          { bidder: 'sharethrough', params: {pkey: 'bKXQTE4sEbQ3iAzsAIk0CoIH'} },
          //   { bidder: 'sharethrough', params: {pkey: 'CSwEBOVkQhxZpzdBa8EJpJkV'} }
          { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
        ]
      },
      {
        "id": "MPUBelowNext",
        "name": `${MPUBelowNextUnitName}`,
        "path": `/76730613/${MPUBelowNextPathway}`,
        "sizes": window.screen.width < 812 ? [[300, 250],[336,280],[320,100]] : [[300, 250],[336,280]],
        "bidders": [
          { bidder: 'adagio', params: { organizationId: '1067', site: 'sportaroo-com', adUnitElementId: 'MPUBelowNext', environment: 'desktop', placement: `${MPUBelowNextPathway}` } },
          { bidder: 'adform', params: {mid: 1698819}},
          // //{ bidder: 'appnexus', params: {placementId: '20942291'}},
          { bidder: 'criteo', params: {networkId: 8147}},
          // { bidder: 'ix', params: {siteId: 623497, size: [300, 250]}},
          // { bidder: 'ix', params: {siteId: 623497, size: [336, 280]}},
          // //{ bidder: 'justpremium', params: {zone: 115712, allow: ["is"]}},
          // //{ bidder: 'districtm', params: {placementId: 16152397} },
          // //{ bidder: 'districtmDMX', params: {dmxid: 374438, memberid : 101868} },
          { bidder: 'medianet', params: {cid: '8CUJF5HOT', crid: '275368544'}},
          { bidder: 'onetag', params: {pubId: '654b33df39ee492'}},
          { bidder: 'openx', params: {unit: '559450483', delDomain: 'lqr-d.openx.net'}},
          { bidder: 'pubmatic', params: {publisherId: '159940', adSlot: '5172555'}},
          // //{ bidder: 'richaudience', params: { pid: 'EGWgVO2XQq', supplyType: 'site' }},
          { bidder: 'sovrn', params: {tagid: 1141124}},
          // { bidder: 'teads', params: {pageId: '121182', placementId: '135712'}},
          // { bidder: 'triplelift', params: {inventoryCode: "TrendscatchersCOM_NEXT_MPUBelowNext_Prebid"}},
          { bidder: 'smartadserver', params: { siteId: 596129, pageId: 1823641, formatId: 115311}},
          // { bidder: 'taboola', params: { tagId: 'LeaderBelowTitle', publisherId: '1490051' }},
          { bidder: 'rise', params: { org: '62050c0300be360001986a59' }},
          { bidder: 'sharethrough', params: {pkey: 'JKQLt6ckFaHwxpqHiPTPgpOj'} },
          { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
        ],
        "mobile_bidders": [
          { bidder: 'adagio', params: { organizationId:'1067', site: 'sportaroo-com',adUnitElementId: 'MPUBelowNext', environment:'mobile', placement:`${MPUBelowNextPathway}`} },
          { bidder: 'adform', params: { mid: 1698819 } },
          //  // { bidder: 'appnexus', params: {placementId: '20942291'} },
          { bidder: 'criteo', params: { networkId: 8147 } },
          //   { bidder: 'ix', params: {siteId: 671485, size:[300, 250]} },
          //   { bidder: 'ix', params: {siteId: 671485, size:[336, 280]} },
          //   //{ bidder: 'justpremium', params: {zone: 115712} },
          //   //{ bidder: 'districtm', params: {placementId: 16152397} },
          //   //{ bidder: 'districtmDMX', params: {dmxid: 374438, memberid : 101868} },
          { bidder: 'medianet', params: {cid: '8CUJF5HOT', crid: '275368544'}},
          { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
          { bidder: 'openx', params: {unit: '559450483', delDomain: 'lqr-d.openx.net'}},
          { bidder: 'pubmatic', params: {publisherId: '159940', adSlot: '5172555'}},
          //   //{ bidder: 'richaudience', params: { pid: 'EGWgVO2XQq', supplyType: 'site' }},
          { bidder: 'sovrn', params:{ tagid: 1141157 } },
          //   { bidder: 'sovrn', params:{ tagid: 934675 } },
          //   //{ bidder: 'sublime', params:{ zoneId: 33294 , notifyId: (function b(a){return a?(a^Math.random()*16>>a/4).toString(16):([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g,b)})(); return window.sublime.notifyId;})() } },
          //   { bidder: 'teads', params: { pageId: '121182', placementId: '131481'} },
          //   { bidder: 'triplelift', params: {inventoryCode: "TrendscatchersCOM_mobile_NEXT_MPUBelowNext_Prebid"} },
          { bidder: 'smartadserver', params: { siteId: 596129, pageId: 1823641, formatId: 115311}},
          //   { bidder: 'taboola', params: { tagId: 'LeaderBelowTitle', publisherId: '1490051' }},
          { bidder: 'rise', params: { org: '62050c0300be360001986a59' }},
          { bidder: 'sharethrough', params: {pkey: 'aSompqGkj7f2diSuLrpw5zQD'} },
          { bidder: 'sharethrough', params: {pkey: 'i6KXcYnEEvuHkiSRG1XvvCf8'} },
          { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
        ]
      },
      {
        "id": "MPUCenterRight",
        "name":  MPUCenterRightUnitName,
        "path": `/76730613/${MPUCenterRightPathway}`,
        "sizes": window.screen.width < 812 ? [[300, 250],[336,280],[320,100],[1,1]] : [[300, 250],[336,280],[1,1]],
        "bidders": [
          { bidder: 'adagio', params: { organizationId: '1067', site: 'sportaroo-com', adUnitElementId: 'MPUCenterRight', environment: 'desktop', placement: `${MPUCenterRightPathway}` } },
          { bidder: 'adform', params: {mid: 1698816}},
          //  // { bidder: 'appnexus', params: {placementId: '20942285'}},
          { bidder: 'criteo', params: {networkId: 8147}},
          //   { bidder: 'ix', params: {siteId: 623504, size: [300, 250]}},
          //   { bidder: 'ix', params: {siteId: 623504, size: [336, 280]}},
          //   { bidder: 'justpremium', params: {zone: 115712, allow: ["is"]}},
          //   //{ bidder: 'districtm',  params: { placementId: 16005292 } },
          //   //{ bidder: 'districtmDMX', params: { dmxid: 367606, memberid : 101868 } },
          { bidder: 'medianet', params: {cid: '8CUJF5HOT', crid: '213381248'}},
          { bidder: 'onetag', params: {pubId: '654b33df39ee492'}},
          { bidder: 'openx', params: {unit: '559450481', delDomain: 'lqr-d.openx.net'}},
          { bidder: 'pubmatic', params: {publisherId: '159940', adSlot: '5172555'}},
          //   //{ bidder: 'richaudience', params: { pid: 'i6doEjb0Cb', supplyType: 'site' }},
          { bidder: 'sovrn', params: {tagid: 1141129}},
          //   { bidder: 'teads', params: {pageId: '121182', placementId: '135712'}},
          //   { bidder: 'triplelift', params: {inventoryCode: "TrendscatchersCOM_NEXT_MPUCenterRight_Prebid"}},
          { bidder: 'smartadserver', params: { siteId: 596129, pageId: 1823641, formatId: 115311}},
          //   { bidder: 'taboola', params: { tagId: 'MPUCenterRight', publisherId: '1490051' }},
          { bidder: 'rise', params: { org: '62050c0300be360001986a59' }},
          { bidder: 'sharethrough', params: {pkey: 'Osx0NQc0cydP8eHNE3ZPyBoG'} },
          { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
        ],
        "mobile_bidders": [
          { bidder: 'adagio', params: { organizationId: '1067', site: 'sportaroo-com', adUnitElementId: 'MPUCenterRight', environment: 'mobile', placement: `${MPUCenterRightPathway}` } },
          { bidder: 'adform', params: {mid: 1698816}},
          // //  { bidder: 'appnexus', params: {placementId: '20942285'}},
          { bidder: 'criteo', params: {networkId: 8147}},
          //   { bidder: 'ix', params: {siteId: 623524, size: [300, 250]}},
          //   { bidder: 'ix', params: {siteId: 623524, size: [336, 280]}},
          //   { bidder: 'ix', params: {siteId: 623524, size: [320, 100]}},
          //   { bidder: 'justpremium', params: {zone: 115712, allow: ["is"]}},
          //   //{ bidder: 'districtm',  params: { placementId: 16005292 } },
          //   //{ bidder: 'districtmDMX', params: { dmxid: 367606, memberid : 101868 } },
          { bidder: 'medianet', params: {cid: '8CUJF5HOT', crid: '213381248'}},
          { bidder: 'onetag', params: {pubId: '654b33df39ee492'}},
          { bidder: 'openx', params: {unit: '559450481', delDomain: 'lqr-d.openx.net'}},
          { bidder: 'pubmatic', params: {publisherId: '159940', adSlot: '5172555'}},
          //   //{ bidder: 'richaudience', params: { pid: 'QLncydO1NV', supplyType: 'site' }},
          { bidder: 'sovrn', params: {tagid: 1141158}},
          //   //{ bidder: 'sublime', params:{ zoneId: 33294 , notifyId: (function b(a){return a?(a^Math.random()*16>>a/4).toString(16):([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g,b)})(); return window.sublime.notifyId;})() } },
          //   { bidder: 'teads', params: {pageId: '121182', placementId: '135712'}},
          //   { bidder: 'triplelift', params: {inventoryCode: "TrendscatchersCOM_NEXT_MPUCenterRight_Prebid"}},
          { bidder: 'smartadserver', params: { siteId: 596129, pageId: 1823641, formatId: 115311}},
          //   { bidder: 'taboola', params: { tagId: 'MPUCenterRight', publisherId: '1490051' }},
          { bidder: 'rise', params: { org: '62050c0300be360001986a59' }},
          { bidder: 'sharethrough', params: {pkey: 'DutQpACJQmtPMLg9sPfD9uq3'} },
          { bidder: 'sharethrough', params: {pkey: '6ztlN7jtmgsaPmldBXWbAU72'} },
          { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
        ]
      }
    ];

  // prettier-ignore
  if (window.screen.width > 1440) {
      let rcMissingIX = adUnits.find(ad => ad.id === 'RightColumn');
      let lcMissingIX = adUnits.find(ad => ad.id === 'LeftColumn');
      // Right
      // rcMissingIX.bidders.push({ bidder: 'ix', params: {siteId: 623502, size:[300, 600]} });
      // rcMissingIX.bidders.push({ bidder: 'ix', params: {siteId: 623502, size:[300, 250]} });
      // rcMissingIX.bidders.push({ bidder: 'sovrn', params: {tagid: 1103062}});
      // Left
      // lcMissingIX.bidders.push({ bidder: 'ix', params: {siteId: 697578, size:[300, 600]} });
      // lcMissingIX.bidders.push({ bidder: 'ix', params: {siteId: 697578, size:[300, 250]} });
      // lcMissingIX.bidders.push({ bidder: 'sharethrough', params: {pkey: '6fBhIvR7dgvsOtUo52333oGc'} });
      // lcMissingIX.bidders.push({ bidder: 'sovrn', params: {tagid: 1103064}});
    }
  return adUnits;
}
